import React from "react";
import {
  useDeleteMessage,
  useGetMessagesList,
} from "../../../react-query/hooks/messages";
import IMAGES from "../../../utils/constants/images";
import { formatDate } from "../../../utils/helpers";
import { toast } from "react-custom-alert";
import CustomAlert from "../../../components/common/alert";
import Swal from "sweetalert2";

type Props = {};

const Notifications = (props: Props) => {
  const { data: getMessagesResponse } = useGetMessagesList();
  const { mutateAsync: mutateAsyncDeleteMessage, isSuccess } =
    useDeleteMessage();

  const handleDeleteMessage = async (id: string) => {
    CustomAlert({
      title: " Are you sure want to delete the message?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await mutateAsyncDeleteMessage({
            messageId: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  };
  // Show success toast when a doc is successfully deleted
  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Message deleted successfully");
    }
  }, [isSuccess]);
  return (
    <div className="container common-section">
      <div className="row mt-5 g-4">
        <div className="col-12">
          <h1 className="company-title-txt">Notifications</h1>
          <div className="notification-main-wrapper">
            {getMessagesResponse?.messagesList?.length === 0 ? (
              <div className="not-found-notifications">
                <p>Notification not found !</p>
              </div>
            ) : (
              getMessagesResponse?.messagesList?.map((item: any) => (
                <div className="notification_main_item">
                  <div className="notification_main_inner_item rounded shadow-sm py-3 px-1">
                    <p>{item?.message}</p>
                    <button onClick={() => handleDeleteMessage(item?._id)}>
                      <img src={IMAGES.rejectedLogo} alt="" />
                    </button>
                  </div>
                  <span>{formatDate(item?.createdAt)}</span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
