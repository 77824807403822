import * as Yup from "yup";

const profileBasicInfoInitialValues = {
  name: "",
  pinCode: "",
  email: "",
  countryCode: "",
  phoneNumber: "",
  profileImage: "",
  coverImage: "",
  companySize: "",
  yearFounded: "",
};

const profileBasicInfoValidationSchema = Yup.object().shape({
  name: Yup.string().required("Company name is required*"),
  // founder: Yup.string().required("Founder is required*"),
  // pinCode: Yup.string().required("Pin code is required*"),
  email: Yup.string().email().required("Email is required*"),
  phoneNumber: Yup.string().required("Mobile number is required*"),
  countryCode: Yup.string().required("Country Code is required*"),
  profileImage: Yup.string().required("Profile logo is required*"),
  //   coverImage: Yup.string().required("Cover image is required*"),
  companySize: Yup.string().required("Company size is required*"),
  yearFounded: Yup.string().required("Year founded is required*"),
});

export { profileBasicInfoInitialValues, profileBasicInfoValidationSchema };
