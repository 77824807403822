const LOGO = {
  mainLogo: "/assets/images/logo/logo.png",
  newLogo: "/assets/images/logo/main-logo.svg",
  profileLogo: "/assets/images/theme/company-profile.png",
  verifyLogo: "/assets/images/logo/verified.png",
  rejectedLogo: "/assets/images/logo/rejected.png",
};

const ICON = {
  eyeOpen: "/assets/images/icons/eye-open.svg",
  eyeClose: "/assets/images/icons/eye-close.svg",
  editIcon: "/assets/images/icons/edit-icon.svg",
  leftArrowIcon: "/assets/images/icons/left-table-arrow.svg",
  rightArrowIcon: "/assets/images/icons/right-table-arrow.svg",
  notificationIcon: "/assets/images/icons/request.png",
};

const THEME = {
  mainTheme: "/assets/images/theme/auth.png",
  defaultImage: "/assets/images/theme/company-profile.png",
  notFoundImage: "/assets/images/theme/notFound.avif",
  success: "/assets/images/theme/sucess.gif",
  cancel: "/assets/images/theme/cancel.gif",
};

const IMAGES = {
  ...LOGO,
  ...ICON,
  ...THEME,
};

export default IMAGES;
