import GoogleMapReact from "google-map-react";

interface IProps {
  setGetLngLng: any;
  setFieldValue?: any;
  latLng: any;
}

export default function CustomMap({
  setGetLngLng,
  setFieldValue,
  latLng,
}: IProps) {
  console.log(latLng);

  const defaultProps = {
    center: {
      lat: Number(latLng.lat),
      lng: Number(latLng.lng),
    },
    zoom: 10,
  };

  return (
    <div style={{ height: "30vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={(e: any) => {
          setGetLngLng({
            lat: e.lat,
            lng: e.lng,
          });
          setFieldValue("location", e.lat);
        }}
      >
        <img
          src="/assets/images/theme/map-pin.png"
          alt="..."
          height={25}
          width={25}
        />
      </GoogleMapReact>
    </div>
  );
}
