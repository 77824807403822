/* eslint-disable jsx-a11y/anchor-is-valid */
import IMAGES from "../../../utils/constants/images";

interface IProps {
  count: number;
  pageNumber: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
}

const TablePagination = ({ count, pageNumber, totalRecords, onPageChange }: IProps) => {
  const totalPages = Math.ceil(totalRecords / count);

  const handlePrevClick = () => {
    if (pageNumber > 1) {
      onPageChange(pageNumber - 1);
    }
  };

  const handleNextClick = () => {
    if (pageNumber < totalPages) {
      onPageChange(pageNumber + 1);
    }
  };

  return (
    <div className="table-pagination">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center align-items-center gap-3 gap-sm-4">
          <li className="page-item">
            <span className="page-text">Page</span>
          </li>
          <li className={`page-item ${pageNumber === 1 ? "disabled" : ""}`}>
            <button className="page-arrow" onClick={handlePrevClick}>
              <img src={IMAGES.leftArrowIcon} alt="arrow" />
            </button>
          </li>
          <li className="page-item">
            <span className="page-link active">{pageNumber}</span>
          </li>
          <li className={`page-item ${pageNumber === totalPages ? "disabled" : ""}`}>
            <button className="page-arrow" onClick={handleNextClick}>
              <img src={IMAGES.rightArrowIcon} alt="arrow" />
            </button>
          </li>
          <li className="page-item">
            <a className="last-page-link" onClick={() => onPageChange(totalPages)}>
              {totalPages}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TablePagination;
