import React from "react";
import "./App.css";
import { ToastContainer } from "react-custom-alert";
import { AuthProvider } from "./app/context/authContext";
import RouteStack from "./app/routes/routeStack";
import "react-loading-skeleton/dist/skeleton.css";
import "react-custom-alert/dist/index.css";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./app/react-query/services/networking.service";
import LogoutSession from "./app/utils/helpers/logoutSession";
import "react-phone-input-2/lib/bootstrap.css";

function App() {
  // handle function for logout when Session expired
  const Logout = React.useCallback(() => {
    return <LogoutSession />;
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouteStack />
        <Logout />
        <ToastContainer floatingTime={1500} />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
