import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth } from "../../utils/interface";
import {
  mutatePostFn,
  mutatePutFn,
  QueryReturnType,
} from "../services/networking.service";
import ENDPOINTS from "../services/endPoints";

// Define the interface for props used in the hook
interface IProps {
  data: FormData;
}
interface IIndustryListProps {
  search: string;
}

// Custom hook for company profile creating
export const useCreateCompanyProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IProps>(
    (data) => mutatePostFn(ENDPOINTS.CREATE_COMPANY_PROFILE, data.data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for company profile detail
export const useGetCompanyProfile = (): QueryReturnType<any> => {
  return useQuery<never, never, any>(ENDPOINTS.COMPANY_PROFILE_DETAIL, {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};

// Custom hook for company profile detail
export const useGetIndustryList = ({
  search,
}: IIndustryListProps): QueryReturnType<any> => {
  return useQuery<never, never, IIndustryListProps>(
    [ENDPOINTS.GET_INDUSTRIES_lIST, { search: search }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};

// Custom hook for company profile edit
export const useEditCompanyProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IProps>(
    (data) => mutatePutFn(ENDPOINTS.EDIT_COMPANY_PROFILE, data.data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
