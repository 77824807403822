import React from "react";

const CustomCompanyTypeDropdown = ({
  options,
  selectedValue,
  onSelect,
}: any) => {
  // State for managing whether the dropdown is open
  const [isOpen, setIsOpen] = React.useState(false);

  // State to check if the "Other" option is selected
  const [isOther, setIsOther] = React.useState(false);

  // State to hold the value of the new custom option
  const [newOption, setNewOption] = React.useState("");

  // Reference for detecting clicks outside of the dropdown
  const ref = React.useRef<HTMLDivElement>(null);

  // Effect to handle closing the dropdown and resetting "Other" when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Close dropdown and reset "Other" state if clicked outside the dropdown
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
        setIsOther(false);
      }
    };

    // Add event listener for detecting clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Function to handle the selection of a dropdown option
  const handleSelect = (option: string) => {
    if (option === "Other") {
      // If "Other" is selected, show the input field for adding a new option
      setIsOther(true);
    } else {
      // If a regular option is selected, call onSelect and close the dropdown
      onSelect(option);
      setIsOpen(false);
      setIsOther(false);
    }
  };

  // Function to handle adding a new custom option
  const handleAddNewOption = () => {
    if (newOption) {
      onSelect(newOption);
      setIsOpen(false);
      setIsOther(false);
      setNewOption("");
    }
  };

  return (
    <div className="custom-dropdown form-select common-input" ref={ref}>
      <div
        className={`dropdown-header ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedValue || "Select Company Type"}
      </div>
      {isOpen && (
        <div className="dropdown-body">
          <ul className="dropdown-list">
            {options.map((option: string, index: number) => (
              <li
                className="dropdown-item"
                key={index}
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
            {!isOther && (
              <li
                className="dropdown-item"
                onClick={() => handleSelect("Other")}
              >
                Other
              </li>
            )}
          </ul>
          {isOther && (
            <div className="custom-option-input">
              <input
                type="text"
                placeholder="Type new company type"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
              />
              <button onClick={handleAddNewOption}>Add</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomCompanyTypeDropdown;
