import * as Yup from "yup";

const companyProfileInitialValues = {
  name: "",
  address: "",
  // founder: "",
  description: "",
  pinCode: "",
  email: "",
  website: "",
  countryCode: "",
  phoneNumber: "",
  socialLink: "",
  profileImage: "",
  coverImage: "",
  industryType: "",
  companyType: "",
  companySize: "",
  yearFounded: "",
};

const companyProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required("Company name is required*"),
  address: Yup.string().required("Address is required*"),
  industryType: Yup.string().required("At least one industry is required*"),
  // founder: Yup.string().required("Founder is required*"),
  description: Yup.string().required("Description is required*"),
  // pinCode: Yup.string().required("Pin code is required*"),
  email: Yup.string().email().required("Email is required*"),
  website: Yup.string()
    .required("Website link is required*")
    .test("is-valid-website", "Enter valid url*", (value) => {
      const simpleUrlPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      const httpsPattern = /^https:\/\/.+$/; // https://example.com
      const wwwPattern = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      return (
        simpleUrlPattern.test(value) ||
        httpsPattern.test(value) ||
        wwwPattern.test(value)
      );
    }),
  phoneNumber: Yup.string().required("Mobile number is required*"),
  countryCode: Yup.string().required("Country Code is required*"),
  // socialLink: Yup.string().required("Enter a valid social media link*"),
  profileImage: Yup.string().required("Profile logo is required*"),
  coverImage: Yup.string().required("Cover image is required*"),
  companyType: Yup.string().required("At least one company type is required*"),
  companySize: Yup.string().required("Company size is required*"),
  yearFounded: Yup.string().required("Year founded is required*"),
});

export { companyProfileInitialValues, companyProfileValidationSchema };
