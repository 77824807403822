import { ClipLoader } from "react-spinners";
import { formatDate } from "../../../utils/helpers";
import { ISubscriptionProps } from "../../../utils/interface";

interface IProps {
  planTitle?: string;
  planAmount: number;
  planDescription?: string;
  isSubscribed?: boolean;
  endDate?: string;
  handlePayment: () => void;
  handleCancelSubscription: () => void;
  disabled: boolean;
  data: ISubscriptionProps;
  isLoading: boolean;
  isExpired: boolean;
  metaData: object;
}

const SubscriptionPlans = ({
  planAmount,
  planDescription,
  planTitle,
  isSubscribed,
  handlePayment,
  handleCancelSubscription,
  disabled,
  endDate,
  isLoading,
  isExpired,
  metaData,
}: IProps) => {
  return (
    <div
      className="col-sm-6 col-md-4 col-lg-custom"
      style={{
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <div className="subscription-card">
        <div className="subscription-wrapper">
          <h2>{planTitle}</h2>
          <div className="price-blk">
            <h3>
              <sup>$</sup>
              {planAmount}
              <sub>per year</sub>
            </h3>
          </div>
          <p>{planDescription}</p>
          <div className="subs-action-btn-blk">
            {endDate && !isExpired && !isSubscribed ? (
              <div className="plan-validity-blk">
                <span>Note :</span>
                <p>Your Plan is valid up to</p>
                <sub className="sub-title">{formatDate(endDate)}</sub>
              </div>
            ) : !isSubscribed ? (
              <button className="get-started-btn" onClick={handlePayment}>
                {isLoading ? (
                  <ClipLoader size={14} color="#000000" />
                ) : (
                  "Get Started"
                )}
              </button>
            ) : (
              <>
                <button
                  className="cancle-btn"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </button>
                <div className="plan-validity-blk">
                  <span>Note :</span>
                  <p> Your plan will renewed</p>
                  <sub className="sub-title">{formatDate(endDate)}</sub>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="plan-features-blk">
          <ul className="plan-feature-list">
            {Object.entries(metaData)?.map(
              (metaItem) =>
                metaItem[0] !== "employeeNumber" && <li>{metaItem[1]}</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
