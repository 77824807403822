/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import IMAGES from "../../../utils/constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/routePath";
import { useVerifyOtp } from "../../../react-query/hooks/auth";
import { toast } from "react-custom-alert";
import { globalConfig } from "../../../utils/constants/globalConfig";
import CommonButton from "../../../components/common/commonButton";

const VerifyOtp = () => {
  let nav = useNavigate();
  const location = useLocation();

  let email: any = localStorage.getItem(globalConfig.COMPANY_EMAIL_NUMBER);
  const inputRefs = React.useRef<any>([]);

  const [otpValues, setOtpValues] = React.useState(Array(6).fill(""));
  const [isInputFilled, setIsInputFilled] = React.useState(false);
  const [error, setError] = React.useState(false);

  // Getting verify otp mutation and loading state
  const { mutateAsync, isLoading, isSuccess, data } = useVerifyOtp();

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(data?.message);
      if (
        location?.state?.name === "signup" ||
        location?.state?.name === "login"
      ) {
        nav(RoutePath.checkBasicInfo.path, { state: location?.state?.values });
      } else {
        nav(RoutePath.changePassword.path);
      }
    }
  }, [isSuccess, data]);

  React.useEffect(() => {
    const filled = otpValues.every((value) => value !== "");
    setIsInputFilled(filled);
  }, [otpValues]);

  function handleInputChange(event: any, index: number) {
    const value = event.target.value;
    // Update OTP values array
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    setError(false);
    // Move focus forward if the current input is filled
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
      setError(false);
    }
    // Move focus backward if the current input is empty
    else if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
      setError(true);
    }
  }

  async function handleVerifyClick() {
    try {
      if (!isInputFilled) {
        setError(true);
      } else {
        await mutateAsync({
          email: email || location?.state?.values?.email,
          otp: otpValues.join(""),
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      }
    }
  }
  return (
    <div className="auth-container">
      <div className="form-blk">
        <div className="logo-main">
          <img src={IMAGES.mainLogo} alt="err" />
        </div>
        <div className="form-inner">
          <h1>Verify Otp!</h1>
          <form>
            <div className="mb-3">
              <ul className="otp-input-block">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <li key={index}>
                    <input
                      maxLength={1}
                      value={otpValues[index]}
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(event) => handleInputChange(event, index)}
                    />
                  </li>
                ))}
              </ul>
              {error && <p className="error-text">Please enter valid otp*</p>}
            </div>
            <div className="btn-blk">
              <CommonButton
                type={"button"}
                onClick={handleVerifyClick}
                btnName="Verify"
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="img-blk">
        <img src={IMAGES.mainTheme} alt="" />
      </div>
    </div>
  );
};

export default VerifyOtp;
