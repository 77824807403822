const tabsArray = [
  { id: 1, label: "Accepted" },
  { id: 2, label: "Rejected" },
  { id: 0, label: "Pending" },
];
const tableArray = [
  { name: "Profile" },
  { name: "Name" },
  { name: "Profession" },
  { name: "Mobile number" },
  { name: "Email address" },
  { name: "Request" },
];

export { tabsArray ,tableArray};
