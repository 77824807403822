/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { toast } from "react-custom-alert";
import { useAppContext } from "../../context/authContext";
import { globalConfig } from "../constants/globalConfig";

let instance = 0;
function LogoutSession() {
  const { setIsAuthenticated } = useAppContext();
  const [wantToLogout, setWantToLogOut] = useState(false);

  React.useEffect(() => {
    window.addEventListener("storage", storageEventHandler, false);
  }, []);

  function storageEventHandler() {
    setWantToLogOut(
      localStorage?.getItem(globalConfig?.CARDIFY_LOGOUT) === "true"
        ? true
        : false
    );
    if (localStorage?.getItem(globalConfig?.CARDIFY_LOGOUT) === "true") {
      instance = instance + 1;
    }
  }

  React.useEffect(() => {
    if (wantToLogout && instance === 1) {
      setIsAuthenticated(false);
      localStorage.clear();
      toast.error("Session expired");
    }
    setWantToLogOut(false);
    localStorage?.setItem(globalConfig?.CARDIFY_LOGOUT, "false");
    window.dispatchEvent(new Event("storage"));
  }, [wantToLogout]);

  return null;
}
export default LogoutSession;
