import React from "react";
import Skeleton from "react-loading-skeleton";

const SubscriptionPlansSkelton = () => {
  return (
    <div className="col-md-6 col-xl-4">
      <div className="subscription-card">
        <div className="subscription-wrapper">
          <Skeleton width={"100px"} />
          <div className="price-blk">
            <Skeleton width={"250px"} height={"100px"} />
          </div>
          <Skeleton width={"300px"} />
          <div className="subs-action-btn-blk ">
            <Skeleton width={"450px"} height={"50px"} />
            <Skeleton width={"450px"} height={"50px"} />
          </div>
        </div>
        <div className="plan-features-blk">
          <ul className="plan-feature-list">
            {[1, 2, 3, 4]?.map((index) => (
              <div key={index} className="d-flex gap-3 mb-2">
                <Skeleton
                  width={"15px"}
                  height={"15px"}
                  style={{ borderRadius: "50%" }}
                />
                <Skeleton width={"300px"} />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlansSkelton;
