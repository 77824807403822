/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { useFormik } from "formik";
import CommonInput from "../../../components/common/commonInput";
import {
  useCreateCompanyProfile,
  useGetIndustryList,
} from "../../../react-query/hooks/profile";
import CommonButton from "../../../components/common/commonButton";
import { toast } from "react-custom-alert";
import { useAppContext } from "../../../context/authContext";
import { globalConfig } from "../../../utils/constants/globalConfig";
import CustomIndustryDropdown from "../../../components/screens/profile/customIndustryDropdown";
import CustomCompanyTypeDropdown from "../../../components/screens/profile/customCompanyTypeDropDown";
import CustomMap from "../../../components/common/map";

const CreateProfile = () => {
  // getting data from state
  let location = useLocation();

  // Getting setIsAuthenticated from app context
  const { setIsAuthenticated } = useAppContext();

  let nav = useNavigate();

  const [latLng, setLatLng] = React.useState<any>({
    lat: 52.52,
    lng: 13.405,
  });

  const companyTypeOptions = ["Private", "Non Profit", "Government"];

  const handleCompanyTypeSelect = (selectedType: string) => {
    setFieldValue("companyType", selectedType);
  };

  // Getting create profile mutation and loading state
  const { mutateAsync, isLoading, isSuccess, data } = useCreateCompanyProfile();
  const { data: getIndustryList } = useGetIndustryList({
    search: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.createCompanyProfileInitialValues,
    validationSchema: VALIDATIONS.createCompanyProfileValidationSchema,
    onSubmit: async (values) => {
      let fromData = new FormData();
      try {
        fromData.append("name", location?.state?.name);
        fromData.append("profile_address", values.address);
        fromData.append("description", values.description);
        fromData.append("pinCode", location?.state?.pinCode);
        fromData.append("email", location?.state?.email);
        fromData.append("website", values.website);
        fromData.append("phoneNumber", location?.state?.phoneNumber);
        fromData.append("countryCode", location?.state?.countryCode);
        fromData.append("socialLink", values.socialLink);
        fromData.append("profileImage", location?.state?.profileImage);
        fromData.append("coverImage", location?.state?.coverImage || null);
        fromData.append("companySize", location?.state?.companySize);
        fromData.append("companyType", values.companyType);
        fromData.append("industryType", values.industryType);
        fromData.append("yearFounded", location?.state?.yearFounded);
        fromData.append("lat", latLng.lat);
        fromData.append("lng", latLng.lng);
        await mutateAsync({ data: fromData });
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Profile created successfully");
      localStorage.setItem(
        globalConfig.CARDIFY_PROFILE_TOKEN,
        data?.data?.token
      );
      localStorage.setItem(
        globalConfig.CARDIFY_LOGIN_DETAIL,
        JSON.stringify(data?.data)
      );
      setIsAuthenticated(true);
      resetForm();
    }
  }, [isSuccess]);

  return (
    <div className="container p-5 position-relative">
      <button className="back-btn" onClick={() => nav(-1)}>
        Back
      </button>
      <div className="row">
        <div className="col-12">
          <h1 className="company-title-txt">Create Company Profile</h1>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row gx-5 gy-3  mt-4">
          <div className="col-sm-6">
            <CustomIndustryDropdown
              options={getIndustryList?.data}
              selectedValue={values.industryType}
              onSelect={(industry: string) =>
                setFieldValue("industryType", industry)
              }
            />
            {errors.industryType && touched.industryType && (
              <p className="error-text ">{errors.industryType}</p>
            )}
          </div>
          <div className="col-sm-6">
            <CustomCompanyTypeDropdown
              options={companyTypeOptions}
              selectedValue={values.companyType}
              onSelect={handleCompanyTypeSelect}
            />
            {errors.companyType && touched.companyType && (
              <p className="error-text ">{errors.companyType}</p>
            )}
          </div>
          <div className="col-sm-6">
            <CommonInput
              name="website"
              errors={errors.website}
              touched={touched.website}
              placeholder="Website"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.website}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.socialLink}
              touched={touched.socialLink}
              name="socialLink"
              placeholder="Social Link"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.socialLink}
            />
          </div>
          <div className="col-sm-6">
            <CommonInput
              errors={errors.address}
              name="address"
              touched={touched.address}
              placeholder="Address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
            />
          </div>
          <div className="col-sm-6">
            <textarea
              rows={3}
              className="form-control common-input"
              name="description"
              placeholder="Enter Description Here..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
            {errors.description && touched.description && (
              <p className="error-text">{errors.description}</p>
            )}
          </div>

          <div className="col-sm-12">
            <label className="input-label">Select Location</label>
            <CustomMap
              latLng={latLng}
              setGetLngLng={setLatLng}
              setFieldValue={setFieldValue}
            />
          </div>
          {errors.location && touched.location && (
            <p className="error-text">{errors.location}</p>
          )}
        </div>
        <div className="col">
          <div className="save-btn-blk">
            <CommonButton
              disabled={isLoading}
              btnName="Save"
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProfile;
