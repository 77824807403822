/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { footersArray } from "../../utils/seeds/headersArray";

const Footer = () => {
  let navigate = useNavigate();

  function handleNavigate(path: string) {
    navigate(path);
  }

  return (
    <footer>
      <div className="container-fluid px-0">
        <div className="row g-0">
          <div className="col-12">
            <div className="row justify-content-between align-items-center shadow-sm bg-body border-top gx-0 py-3">
              <p className="col-4 text-start text-muted ps-3 mb-0">
                © 2024 Company, Inc
              </p>
              <ul className="col-8 nav justify-content-end pe-3 mb-0">
                {footersArray?.map((items, index) => {
                  return (
                    <li
                      className="nav-item"
                      onClick={() => handleNavigate(items.path)}
                      key={index}
                    >
                      <a className="nav-link px-5 text-muted">{items?.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
