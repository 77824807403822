/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { RoutePath } from "../../../routes/routePath";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../../utils/constants/images";

const CancelTransaction = () => {
  const nav = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      nav(RoutePath.subscriptionPlan.path);
    }, 2500);
  }, []);
  return (
    <div className="container">
      <div className="row ">
        <div className="col-12">
          <div className="success-blk">
            <h1>Transaction Cancel</h1>
            <img src={IMAGES.cancel} alt="cancel" />
            <button onClick={() => nav(RoutePath.subscriptionPlan.path)}>
              Back to Subscription Plans
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelTransaction;
