import React from "react";
import IMAGES from "../../utils/constants/images";

const PageNotFound = () => {
  return (
    <div className="container">
      <div className="row text-center p-5">
        <div className="col-12">
          <img src={IMAGES.notFoundImage} alt="err" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
