const apiEndURL = {
  // auth
  COMPANY_LOGIN: "company/login",
  COMPANY_REGISTER: "company/register",
  VERIFY_EMAIL: "company/send-otp",
  VERIFY_OTP: "company/verify-otp",
  CHANGE_PASSWORD: "company/change-password",

  //profile
  CREATE_COMPANY_PROFILE: "/company/create-profile",
  EDIT_COMPANY_PROFILE: "/company/edit-profile",
  COMPANY_PROFILE_DETAIL: "/company/profile",
  GET_INDUSTRIES_lIST: "/company/industry-list",

  //subscription
  GET_SUBSCRIPTION: "/company/subscribed-plans",
  PURCHASE_SUBSCRIPTION: "/company/purchase-subscription",
  UPDATE_PURCHASE_SUBSCRIPTION: "/company/upgrade-plan",
  CANCEL_SUBSCRIPTION: "/company/cancel-subscription",
  //requests
  GET_REQUESTS_LIST: "/company/requestList",
  ACCEPT_REQUEST: "/company/request-accept",
  REJECT_REQUEST: "/company/request-reject",
  DELETE_REQUEST: "/company/request-delete",

  //document
  UPLOAD_DOUCMENTS: "/company/upload-docs",
  GET_DOUCMENT_LIST: "/company/getDocs",
  DELETE_DOUCMENT: "/company/deleteDocById",

  //messages
  GET_MESSAGES: "/company/messageList",
  MESSAGE_DELETE: "/company/message-delete",
  MESSAGE_CLEAR_ALL: "/company/message-clear",
};

const ENDPOINTS = {
  ...apiEndURL,
};

export default ENDPOINTS;
