import { useMutation, useQuery, useQueryClient } from "react-query";
import { mutatePostFn, QueryReturnType } from "../services/networking.service";
import ENDPOINTS from "../services/endPoints";
import { Auth } from "../../utils/interface";

interface IProps {
  priceId: string;
  subscriptionId: string;
}

// Custom hook for subscription list
export const useGetSubscriptionList = (): QueryReturnType<any> => {
  return useQuery<never, never, any>(ENDPOINTS.GET_SUBSCRIPTION, {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};

// Custom hook for purchase subscription
export const usePurchaseSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, { priceId: string }>(
    (data) => mutatePostFn(ENDPOINTS.PURCHASE_SUBSCRIPTION, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for purchase subscription
export const useUpdatePurchaseSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IProps>(
    (data) => mutatePostFn(ENDPOINTS.UPDATE_PURCHASE_SUBSCRIPTION, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for cancel subscription
export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.CANCEL_SUBSCRIPTION, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
