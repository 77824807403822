import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import { useAppContext } from "../context/authContext";
import { RoutePath } from "./routePath";
import { globalConfig } from "../utils/constants/globalConfig";
import PageNotFound from "../components/common/pageNotFound";
import React from "react";

const RouteStack = () => {
  const { isAuthenticated } = useAppContext();
  const isSubscribed = JSON.parse(
    localStorage.getItem(globalConfig.CARDIFY_LOGIN_DETAIL) || "null"
  );
  const isCompanyProfileVerified =
    isSubscribed?.isCompanyProfileVerified || false;

  // Default path logic for authenticated users
  const determinePath = () => {
    if (!isSubscribed?.isAnyPlanSubscribed) {
      // Case 1: Show only Subscription Plan if not subscribed
      return RoutePath.subscriptionPlan.path;
    } else if (!isCompanyProfileVerified) {
      // Case 2: Show only Document Management if subscribed but not verified
      return RoutePath.documentManagement.path;
    } else {
      // Case 3: Show Request Management or Profile Management if verified
      return RoutePath.requestManagement.path;
    }
  };

  const PublicRoute = () => {
    if (isAuthenticated) {
      return <Navigate to={determinePath()} replace />;
    }
    return <Outlet />;
  };

  // Private Route
  const PrivateRoute = () => {
    const location = useLocation();
    const showHeaderFooter = ![
      RoutePath.success.path,
      RoutePath.cancelTransaction.path,
    ].includes(location.pathname);

    if (!isAuthenticated) {
      return <Navigate to={RoutePath.login.path} replace />;
    }

    return (
      <>
        {showHeaderFooter && <Header />}
        <Outlet />
        {showHeaderFooter && <Footer />}
      </>
    );
  };
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path={"/"} element={RoutePath.login.component} />
          {Object.keys(RoutePath)
            .filter((key) => RoutePath[key].type === "public")
            .map((key) => (
              <Route
                key={key}
                path={RoutePath[key].path}
                element={RoutePath[key].component}
              />
            ))}
        </Route>
        <Route element={<PrivateRoute />}>
          {Object.keys(RoutePath)
            .filter((key) => RoutePath[key].type === "private")
            .map((key) => (
              <Route
                key={key}
                path={RoutePath[key].path}
                element={RoutePath[key].component}
              />
            ))}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default RouteStack;
