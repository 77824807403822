import { KeyboardEventHandler } from "react";

const numberOnly: KeyboardEventHandler<HTMLInputElement> = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};
function handleKeyDown(event: any) {
  if (event.key === " " && event.target.selectionStart === 0) {
    event.preventDefault();
  }
}

function formatDate(dateString: any) {
  const date = new Date(dateString);

  // Get the day of the month
  const day = date.getDate();

  // Get the ordinal suffix for the day
  const dayWithSuffix =
    day +
    (day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th");

  // Get the month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];

  // Get the year
  const year = date.getFullYear();

  return `${dayWithSuffix} ${month}, ${year}`;
}
export { numberOnly, handleKeyDown, formatDate };
