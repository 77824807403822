import { useMutation, useQueryClient } from "react-query";
import { mutatePostFn } from "../services/networking.service";
import ENDPOINTS from "../services/endPoints";
import { Auth, ICompanyLogin, ICompanyRegister } from "../../utils/interface";

//  interface for the props used in email verification
interface IVerifyEmailProps {
  email: string;
}

//  interface for the props used in OTP verification
interface IVerifyOtpProps {
  email: string;
  otp: string;
}

// interface for the props used in password change
interface IChangePasswordProps {
  email: string;
  newPassword: string;
}

// Custom hook for company registration
export const useCompanyRegister = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, ICompanyRegister>(
    (data) => mutatePostFn(ENDPOINTS.COMPANY_REGISTER, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for company login
export const useCompanyLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, ICompanyLogin>(
    (data) => mutatePostFn(ENDPOINTS.COMPANY_LOGIN, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for email verification
export const useVerifyEmail = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IVerifyEmailProps>(
    (data) => mutatePostFn(ENDPOINTS.VERIFY_EMAIL, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for OTP verification
export const useVerifyOtp = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IVerifyOtpProps>(
    (data) => mutatePostFn(ENDPOINTS.VERIFY_OTP, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for changing password
export const useChangePassword = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IChangePasswordProps>(
    (data) => mutatePostFn(ENDPOINTS.CHANGE_PASSWORD, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
