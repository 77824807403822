import {
  loginInitialValues,
  loginValidationSchema,
  registerInitialValues,
  registerValidationSchema,
} from "./authValidation";
import {
  companyProfileInitialValues,
  companyProfileValidationSchema,
} from "./companyProfile";
import {
  createCompanyProfileInitialValues,
  createCompanyProfileValidationSchema,
} from "./createCompanyProfile";
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
  verifyEmailInitialValues,
  verifyEmailValidationSchema,
} from "./forgotPassword";
import {
  profileBasicInfoInitialValues,
  profileBasicInfoValidationSchema,
} from "./profileBasicInfo";

const INITIALVALUES = {
  loginInitialValues: loginInitialValues,
  verifyEmailInitialValues: verifyEmailInitialValues,
  changePasswordInitialValues: changePasswordInitialValues,
  registerInitialValues: registerInitialValues,
  companyProfileInitialValues: companyProfileInitialValues,
  profileBasicInfoInitialValues: profileBasicInfoInitialValues,
  createCompanyProfileInitialValues: createCompanyProfileInitialValues,
};

const VALIDATIONS = {
  loginValidationSchema: loginValidationSchema,
  verifyEmailValidationSchema: verifyEmailValidationSchema,
  changePasswordValidationSchema: changePasswordValidationSchema,
  registerValidationSchema: registerValidationSchema,
  companyProfileValidationSchema: companyProfileValidationSchema,
  profileBasicInfoValidationSchema: profileBasicInfoValidationSchema,
  createCompanyProfileValidationSchema: createCompanyProfileValidationSchema,
};

export { VALIDATIONS, INITIALVALUES };
