import React, { createContext, useContext, useState } from "react";
import { globalConfig } from "../utils/constants/globalConfig";
import { toast } from "react-custom-alert";

const AppContext = createContext<any>(null);

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const loginToken = localStorage.getItem(globalConfig.CARDIFY_LOGIN_TOKEN);
    const profileToken = localStorage.getItem(
      globalConfig.CARDIFY_PROFILE_TOKEN
    );
    return !!(loginToken || profileToken);
  });

  const isLoggedOut = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    toast.success("Logout Successfully");
  };

  return (
    <AppContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, isLoggedOut }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
