/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IMAGES from "../../utils/constants/images";
import { useAppContext } from "../../context/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import CustomAlert from "./alert";
import { headersArray } from "../../utils/seeds/headersArray";
import { useGetCompanyProfile } from "../../react-query/hooks/profile";
import { useGetRequests } from "../../react-query/hooks/requests";
import { useGetDocsList } from "../../react-query/hooks/document";
import {
  useClearAllMessages,
  useGetMessagesList,
} from "../../react-query/hooks/messages";
import { formatDistanceToNow } from "date-fns";

const Header = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const { isLoggedOut } = useAppContext();
  const [isActive, setIsActive] = React.useState(false);
  const [isListActive, setIsListActive] = React.useState(false);
  const [activeNotification, setActiveNotification] = React.useState(false);
  const [isProfileVerified, setIsProfileVerified] = React.useState(false);

  // Hook to fetch company profile
  const { data } = useGetCompanyProfile();
  const { data: getDocsList } = useGetDocsList();
  const { data: getMessagesResponse } = useGetMessagesList();
  const { mutateAsync: mutateAsyncClearAllMessages } = useClearAllMessages();
  const { data: requestData } = useGetRequests({
    limit: null,
    page: null,
    status: null,
  });

  const pendingRequest = requestData?.data?.requestList?.filter(
    (item: any) => item?.status === 0
  );

  const profileData = data?.data?.profile;
  const isSubscribed = data?.data?.isAnyPlanSubscribed;

  const [updateTime, setUpdateTime] = React.useState(Date.now());

  // Trigger updates every second
  React.useEffect(() => {
    const interval = setInterval(() => setUpdateTime(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  const getTimeDisplay = (createdAt: any) => {
    const secondsAgo = Math.floor(
      (updateTime - new Date(createdAt).getTime()) / 1000
    );

    if (secondsAgo < 5) return "just now";
    if (secondsAgo < 60)
      return `${secondsAgo} sec${secondsAgo > 1 ? "s" : ""} ago`;

    return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
  };

  React.useEffect(() => {
    // Set profile verification status based on the fetched data
    if (getDocsList?.isCompanyProfileVerified === "verified") {
      setIsProfileVerified(true);
      navigate(RoutePath.requestManagement.path);
    } else {
      setIsProfileVerified(false);
    }
  }, [getDocsList?.isCompanyProfileVerified]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleListNavigate = (path: string) => {
    navigate(path);
    setIsListActive(false);
  };

  function handleLogout() {
    CustomAlert({
      title: "Are you sure want to logout?",
      confirmButtonText: "Logout",
      icon: "question",
      onConfirm: () => {
        isLoggedOut();
        handleNavigate(RoutePath.subscriptionPlan.path);
      },
    });
  }
  const getCompanyId = getMessagesResponse?.messagesList?.map(
    (i: any) => i.companyId
  );

  return (
    <header className="shadow-sm p-4 bg-body position-sticky top-0 rounded z-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header-wrapper">
              <div className="header-logo-nav-blk">
                <div className="logo">
                  <a>
                    <img src={IMAGES.newLogo} alt="..." />
                  </a>
                </div>
              </div>
              <div className={`header-nav ${isActive ? "show" : ""}`}>
                <ul className="d-flex list-unstyled gap-5 header-list m-0">
                  {headersArray?.map((items, index) => {
                    if (!isSubscribed && !isProfileVerified) {
                      // Case 1: Show only Subscription Plan if not subscribed
                      if (items.path !== RoutePath.subscriptionPlan.path)
                        return null;
                    } else if (!isProfileVerified) {
                      // Case 2: Show only Document Management if subscribed but not verified
                      if (items.path !== RoutePath.documentManagement.path)
                        return null;
                    } else {
                      // Case 3: Show Request Management if verified
                      if (items.path !== RoutePath.requestManagement.path)
                        return null;
                    }
                    return (
                      <li
                        className="position-relative"
                        onClick={() => {
                          handleNavigate(items?.path);
                          setIsActive(false);
                        }}
                        key={index}
                      >
                        <a
                          className={
                            items?.path === location?.pathname
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          {items?.name}
                        </a>
                        {index === 1 && pendingRequest?.length > 0 && (
                          <span className="top-icon-blk">
                            {pendingRequest?.length}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="header-btn-blk">
                <div className="header-profile-blk d-flex justify-content-end align-items-center gap-4">
                  <div
                    className="notification-blk"
                    style={{
                      position: "relative",
                    }}
                  >
                    <button
                      onClick={() => {
                        setActiveNotification(!activeNotification);
                        setIsListActive(false);
                      }}
                      className={`notification-icon ${
                        activeNotification ? "active" : ""
                      }`}
                    >
                      <img
                        src={IMAGES.notificationIcon}
                        width={18}
                        height={18}
                        alt="err"
                      />
                      {getMessagesResponse?.messagesList?.length > 0 && (
                        <span className="notify-icon-blk">
                          {getMessagesResponse?.messagesList.length}
                        </span>
                      )}
                    </button>
                    <div className="notification_wrapper">
                      <div className="notification-header d-flex justify-content-between align-items-center gap-3">
                        <span>Notifications</span>
                        {getMessagesResponse?.messagesList?.length > 0 && (
                          <button
                            onClick={async () => {
                              await mutateAsyncClearAllMessages({
                                companyId: getCompanyId[0],
                              });
                              setActiveNotification(false);
                            }}
                          >
                            Clear All
                          </button>
                        )}
                      </div>
                      {getMessagesResponse?.messagesList?.length === 0 ? (
                        <div className="not-found-text">
                          <p>Notification not found !</p>
                        </div>
                      ) : (
                        <div className="notification_list">
                          {getMessagesResponse?.messagesList?.map(
                            (item: any) => (
                              <div className="notification_item">
                                <div className="notification_inner_item">
                                  <p
                                    onClick={() => {
                                      navigate(RoutePath.notifications.path);
                                      setActiveNotification(false);
                                    }}
                                  >
                                    {item?.message}
                                  </p>
                                </div>
                                <span>{getTimeDisplay(item?.createdAt)}</span>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="position-relative">
                    <div
                      onClick={() => {
                        setIsListActive(!isListActive);
                        setActiveNotification(false);
                      }}
                      className={`header-profile-img ${
                        isListActive ? "active" : ""
                      }`}
                    >
                      {profileData?.profileImage && (
                        <img
                          src={
                            profileData?.profileImage
                              ? profileData?.profileImage
                              : IMAGES.profileLogo
                          }
                          alt="..."
                        />
                      )}
                    </div>
                    <div className="list_wrapper">
                      <div className="inner-list">
                        <p
                          onClick={() => {
                            navigate(RoutePath.profileDetail.path, {
                              state: {
                                data: profileData,
                              },
                            });
                            setIsListActive(false);
                          }}
                        >
                          Profile
                        </p>
                        {isProfileVerified && (
                          <>
                            <p
                              onClick={() =>
                                handleListNavigate(
                                  RoutePath.subscriptionPlan.path
                                )
                              }
                            >
                              Subscription Plans
                            </p>
                            <p
                              onClick={() =>
                                handleListNavigate(
                                  RoutePath.documentManagement.path
                                )
                              }
                            >
                              My Document
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <button className="logout" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
                <button
                  className={`togglebtn d-xl-none ${isActive ? "active" : ""}`}
                  onClick={() => setIsActive(!isActive)}
                >
                  <span className="top-bar"></span>
                  <span className="middle-bar"></span>
                  <span className="bottom-bar"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
