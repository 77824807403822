import Axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import {
  QueryClient,
  QueryObserverResult,
  UseBaseMutationResult,
  UseInfiniteQueryResult,
} from "react-query";
import { globalConfig } from "../../utils/constants/globalConfig";
import { toast } from "react-custom-alert";

export type QueryReturnType<T> = QueryObserverResult<T, never>;
export type InfiniteQueryReturnType<T> = UseInfiniteQueryResult<T, never>;
export type MutateReturnType<T, U, V> = UseBaseMutationResult<T, V, U, unknown>;

const preFix = "v1/";

const baseUrl =
  process.env.REACT_APP_MODE === "PRO"
    ? process.env.REACT_APP_LIVE_BASE_URL
    : process.env.REACT_APP_DEVELOP_BASE_URL;

const endPointBaseUrl = baseUrl + preFix;

export const client = Axios.create({
  baseURL: endPointBaseUrl,
});

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const encodedLoginToken = localStorage.getItem(
    globalConfig.CARDIFY_LOGIN_TOKEN
  );
  const encodedRegisterToken = localStorage.getItem(
    globalConfig.CARDIFY_REGISTER_TOKEN
  );
  const encodedProfileToken = localStorage.getItem(
    globalConfig.CARDIFY_PROFILE_TOKEN
  );

  config.params = { ...config.params };

  if (config.headers) {
    if (encodedLoginToken) {
      config.headers.Authorization = `Bearer ${encodedLoginToken}`;
    } else if (encodedRegisterToken) {
      config.headers.Authorization = `Bearer ${encodedRegisterToken}`;
    } else if (encodedProfileToken) {
      config.headers.Authorization = `Bearer ${encodedProfileToken}`;
    }
    // Add common headers
    config.headers = {
      ...config.headers,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
    };
  }

  return config;
}

async function authResponseInterceptor(error: any) {
  if (error.response?.status && error.response.status === 401) {
    localStorage.setItem(globalConfig.CARDIFY_LOGOUT, "true");
    window.dispatchEvent(new Event("storage"));
  } else if (
    error?.response?.data?.message === "Profile not found" &&
    error.response.status === 400
  ) {
    localStorage.clear();
    window.location.reload();
    toast.error("Company not found");
  }
  return Promise.reject(error);
}

client.interceptors.response.use(
  (response) => response,
  authResponseInterceptor
);

client.interceptors.request.use(authRequestInterceptor as never);

export const mutateGetFn = async <T>(
  url: string,
  params?: Record<string, unknown>,
  headers?: AxiosRequestConfig["headers"]
): Promise<T> => {
  try {
    const response = await client.get<T>(url, {
      params,
      headers,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const mutatePostFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>,
  headers?: AxiosRequestHeaders
): Promise<U> => {
  const response = await client.post<T, AxiosResponse<U>>(key, data, {
    params,
    headers,
  });
  return response.data;
};

export const mutatePatchFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>
): Promise<U> => {
  const response = await client.patch<T, AxiosResponse<U>>(key, data, {
    params,
  });
  return response.data;
};

export const mutatePutFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>
): Promise<U> => {
  const response = await client.put<T, AxiosResponse<U>>(key, data, {
    params,
  });
  return response.data;
};

export const mutateDeleteFn = async <T, U>(
  key: string,
  data: T,
  params?: Record<string, unknown>
): Promise<U> => {
  const response = await client.delete<T, AxiosResponse<U>>(key, {
    data,
    params,
  });
  return response.data;
};

const formatQueryParams = (
  params: Record<string, string | number> = {},
  pageParam?: number
) => {
  if (params || pageParam) {
    let qs = Object.keys(params)
      .map((key) => {
        const val = params[key];
        return `${val === null || val === undefined ? "" : `${key}=${val}`}`;
      })
      .filter(Boolean)
      .join("&");
    if (
      pageParam !== null &&
      pageParam !== undefined &&
      typeof pageParam === "number"
    ) {
      qs = qs.replace(`page=${pageParam - 1}`, "").concat(`&page=${pageParam}`);
    }
    return qs;
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url, params], pageParam = null }) => {
        if (typeof url === "string") {
          const queryParams = formatQueryParams(params as never, pageParam);
          const { data } = await client.get(
            `${url.toLowerCase()}${queryParams ? `?${queryParams}` : ""}`
          );
          return data;
        }
        throw new Error("Invalid QueryKey");
      },
    },
  },
});
