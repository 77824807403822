import { useMutation, useQuery, useQueryClient } from "react-query";
import ENDPOINTS from "../services/endPoints";
import {
  mutateDeleteFn,
  mutatePostFn,
  QueryReturnType,
} from "../services/networking.service";
import { Auth } from "../../utils/interface";

interface IProps {
  status: number | null;
  limit: number | null;
  page: number | null;
}

// Custom hook for request list
export const useGetRequests = ({
  limit,
  page,
  status,
}: IProps): QueryReturnType<any> => {
  return useQuery<never, never, IProps>(
    [ENDPOINTS.GET_REQUESTS_LIST, { status: status, page: page, limit: limit }],
    {
      notifyOnChangeProps: [
        "data",
        "error",
        "refetch",
        "isRefetching",
        "isSuccess",
        "isLoading",
      ],
    }
  );
};

// Custom hook for accept request
export const useAcceptRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.ACCEPT_REQUEST, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for reject request
export const useRejectRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.REJECT_REQUEST, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for delete request
export const useDeleteRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateDeleteFn(ENDPOINTS.DELETE_REQUEST, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
