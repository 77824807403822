import React from "react";
import { InputProps } from "../../utils/interface";

const CommonInput = ({
  name,
  type,
  errors,
  onChange,
  placeholder,
  touched,
  value,
  onBlur,
  readOnly,
  max,
  min,
  onKeyPress,
}: InputProps) => {
  return (
    <div>
      <input
        type={type}
        className="form-control common-input"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        maxLength={max}
        minLength={min}
        readOnly={readOnly}
        onKeyPress={onKeyPress}
      />
      {errors && touched && <p className="error-text">{errors}</p>}
    </div>
  );
};

export default CommonInput;
