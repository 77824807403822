import { useMutation, useQuery, useQueryClient } from "react-query";
import ENDPOINTS from "../services/endPoints";
import {
  mutateDeleteFn,
  QueryReturnType,
} from "./../services/networking.service";
import { Auth } from "../../utils/interface";

// Custom hook for messages list
export const useGetMessagesList = (): QueryReturnType<any> => {
  return useQuery<never, never, any>(ENDPOINTS.GET_MESSAGES, {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};

// Custom hook for delete message
export const useDeleteMessage = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateDeleteFn(ENDPOINTS.MESSAGE_DELETE, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for clear all messages
export const useClearAllMessages = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateDeleteFn(ENDPOINTS.MESSAGE_CLEAR_ALL, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
