/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import IMAGES from "../../../utils/constants/images";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/routePath";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import CommonInput from "../../../components/common/commonInput";
import { useCompanyLogin } from "../../../react-query/hooks/auth";
import { globalConfig } from "../../../utils/constants/globalConfig";
import { toast } from "react-custom-alert";
import { useAppContext } from "../../../context/authContext";
import CommonButton from "../../../components/common/commonButton";

const Login = () => {
  // Getting setIsAuthenticated from app context
  const { setIsAuthenticated } = useAppContext();

  let nav = useNavigate();

  // State for showing or hiding password
  const [isActive, setIsActive] = React.useState(false);

  // Getting login mutation and loading state
  const { mutateAsync, isLoading, data } = useCompanyLogin();

  useEffect(() => {
    if (data?.data?.isProfileCompleted === true) {
      toast.success("Login successfully");
      localStorage.setItem(globalConfig.CARDIFY_LOGIN_TOKEN, data?.data?.token);
      localStorage.setItem(
        globalConfig.CARDIFY_LOGIN_DETAIL,
        JSON.stringify(data?.data)
      );
      setIsAuthenticated(true);
      resetForm();
    }
  }, [data]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.loginInitialValues,
    validationSchema: VALIDATIONS.loginValidationSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync(values);
      } catch (error: any) {
        if (error?.response?.data?.isEmailVerified === false) {
          toast.error(error?.response?.data?.message);
          setTimeout(() => {
            nav(RoutePath.verifyOtp.path, {
              state: {
                values: values,
                name: "login",
              },
            });
          }, 500);
        } else if (error?.response?.data?.isProfileCompleted === false) {
          toast.error(error?.response?.data?.message);
          setTimeout(() => {
            nav(RoutePath.checkBasicInfo.path, {
              state: values,
            });
          }, 500);
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });
  return (
    <div className="auth-container">
      <div className="form-blk">
        <div className="logo-main">
          <img src={IMAGES.mainLogo} alt="err" />
        </div>
        <div className="form-inner">
          <h1>Welcome Back!</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <CommonInput
                type="text"
                errors={errors.email}
                touched={touched.email}
                name="email"
                placeholder="Email/Mobile Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </div>
            <div className="mb-2">
              <div className="position-relative">
                <CommonInput
                  type={isActive ? "text" : "password"}
                  errors={errors.password}
                  touched={touched.password}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <div onClick={() => setIsActive(!isActive)} className="eye-blk">
                  {isActive ? (
                    <img src={IMAGES.eyeOpen} alt="eyeOpen" />
                  ) : (
                    <img src={IMAGES.eyeClose} alt="eyeClose" />
                  )}
                </div>
              </div>
            </div>
            <div className="forgot-password">
              <span onClick={() => nav(RoutePath.verifyEmail.path)}>
                Fogot Password ?
              </span>
            </div>
            <div className="btn-blk">
              <CommonButton
                btnName="Login"
                disabled={isLoading}
                loading={isLoading}
              />
              <p>
                Don’t have an account?
                <span onClick={() => nav(RoutePath.signUp.path)}> Sign up</span>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="img-blk">
        <img src={IMAGES.mainTheme} alt="" />
      </div>
    </div>
  );
};

export default Login;
